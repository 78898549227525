/**
 * ハンバーガーメニューのトグル機能を設定する関数
 * @param {Object} config - 構成設定オブジェクト
 */
export const setupMenuToggle = ({ headerSelector, openButtonSelector, activeClass, breakpoint }) => {
  const header = document.querySelector(headerSelector);
  const headerOpenButton = document.querySelector(openButtonSelector);

  if (header && headerOpenButton) {
    headerOpenButton.addEventListener('click', () => {
      header.classList.toggle(activeClass);
      document.body.style.overflow = header.classList.contains(activeClass) ? 'hidden' : '';
    });

    const links = header.querySelectorAll('a');
    links.forEach(link => {
      link.addEventListener('click', () => {
        header.classList.remove(activeClass);
        document.body.style.overflow = '';
      });
    });

    window.addEventListener('resize', () => {
      if (window.innerWidth > breakpoint && header.classList.contains(activeClass)) {
        header.classList.remove(activeClass);
        document.body.style.overflow = '';
      }
    });
  }
};

/**
 * 要素の高さを取得して変数として登録
 * @param {Object} config - 構成設定オブジェクト
 */
export const setElementHeightVariable = ({ selector, variableName }) => {
  const targetSelector = document.querySelector(selector);
  if (targetSelector) {
    const selectorHeight = targetSelector.getBoundingClientRect().height;
    document.documentElement.style.setProperty(variableName, `${selectorHeight}px`);
  }
};

/**
 * 緊急情報セクションの表示状態を管理し、ユーザーが情報を閉じた場合に関連動作を実行する。
 * この関数は、緊急情報セクションが存在するかを確認し、セクションの開閉状態をセッションストレージを使用して制御します。
 * 緊急情報を閉じるボタンにイベントリスナーを設定し、閉じる動作時に指定されたコールバックを実行します。
 *
 * @param {Object} params - 関数のパラメータを含むオブジェクト。
 * @param {string} params.emergencySelector - 緊急情報セクションのセレクタ。
 * @param {string} params.emergencyDismissKey - セッションストレージに保存される閉じた状態を識別するためのキー。
 * @param {string} params.emergencyDismissValue - セッションストレージで使用される閉じた状態の値。
 * @param {string} params.emergencyCloseButtonSelector - 緊急情報を閉じるボタンのセレクタ。
 * @param {string} params.emergencyDisplayNoneClass - 緊急情報セクションが非表示時に適用されるクラス名。
 * @param {Function} params.onEmergencyChange - 緊急情報の表示状態が変更された際に呼び出されるコールバック関数。
 */
export const handleEmergencyInfo = ({
  emergencySelector,
  emergencyDismissKey,
  emergencyDismissValue,
  emergencyCloseButtonSelector,
  emergencyDisplayNoneClass,
  onEmergencyChange,
}) => {
  const emergencyElement = document.querySelector(emergencySelector);

  if (!emergencyElement) return;

  const emergencyCloseButtonElement = document.querySelector(emergencyCloseButtonSelector);

  const updateVisibility = () => {
    const dismissed = sessionStorage.getItem(emergencyDismissKey) === emergencyDismissValue;
    emergencyElement.classList.toggle(emergencyDisplayNoneClass, dismissed);
    if (!dismissed) onEmergencyChange();
  };

  updateVisibility();

  if (!emergencyCloseButtonElement) return;
  emergencyCloseButtonElement.addEventListener('click', () => {
    emergencyElement.classList.add(emergencyDisplayNoneClass);
    onEmergencyChange();
    sessionStorage.setItem(emergencyDismissKey, emergencyDismissValue);
  });
};
