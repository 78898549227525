/**
 * フローティングボタンのスクロールに応じた表示切り替え
 * @param {Object} config - 構成設定オブジェクト
 */
export const setupFloatingButtons = ({ floatButtonsSelector }) => {
  const floatButtons = document.querySelector(floatButtonsSelector);
  let startPosition = 0;
  let windowPosition;

  window.addEventListener('scroll', () => {
    windowPosition = window.scrollY;
    if (windowPosition <= startPosition) {
      floatButtons?.classList.add('floatButtons--active');
    } else {
      floatButtons?.classList.remove('floatButtons--active');
    }
    startPosition = windowPosition;
  });
};

/**
 * 検索メニューの表示制御
 * @param {Object} config - 構成設定オブジェクト
 */
export const setupSearchMenu = ({ searchButtonSelector, searchMenuSelector, closeButtonSelector, menuActiveClass }) => {
  const searchButton = document.querySelector(searchButtonSelector);
  const searchMenu = document.querySelector(searchMenuSelector);
  if (!searchMenu) {
    return;
  }
  const searchCloseButton = searchMenu.querySelector(closeButtonSelector);

  if (searchButton) {
    searchButton.addEventListener('click', () => {
      searchMenu.classList.add(menuActiveClass);
      document.body.style.overflow = 'hidden';
    });
  }

  if (searchCloseButton) {
    searchCloseButton.addEventListener('click', () => {
      searchMenu.classList.remove(menuActiveClass);
      document.body.style.overflow = 'auto';
    });
  }
};
