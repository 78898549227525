import Swiper from 'swiper/bundle';
import { swiperOptions } from './module/SwiperFunctions';
import { initTabs } from './module/TabFunctions';
import imageMapResize from 'image-map-resizer';
// eslint-disable-next-line import/no-extraneous-dependencies
import $ from 'jquery';

document.addEventListener('DOMContentLoaded', () => {
  // main view
  if (document.querySelector('.mainView__swiper')) {
    // サムネイル
    const sliderThumbnail = new Swiper('.mainView__swiperThumbs', {
      slidesPerView: 3.3, // サムネイルの枚数
      spaceBetween: 16,
    });

    new Swiper(
      '.mainView__swiper',
      swiperOptions({
        slidesPerView: 1,
        spaceBetween: 20,
        navigation: {
          nextEl: '.u-swiperButton--next',
          prevEl: '.u-swiperButton--prev',
        },
        pagination: {
          el: '.u-swiperPagination',
          bulletClass: 'u-swiperPaginationBullet',
          bulletActiveClass: 'u-swiperPaginationBullet--active',
          clickable: true,
        },
        thumbs: {
          swiper: sliderThumbnail,
        },
      })
    );
  }

  // pick up
  if (document.querySelector('.topPickUp__swiper')) {
    new Swiper(
      '.topPickUp__swiper',
      swiperOptions({
        slidesPerView: 1.2,
        spaceBetween: 20,
        navigation: {
          nextEl: '.topPickUp .u-articleSwiperButton--next',
          prevEl: '.topPickUp .u-articleSwiperButton--prev',
        },
        breakpoints: {
          768: {
            slidesPerView: 'auto',
            spaceBetween: 32,
          },
        },
      })
    );
  }
});

window.addEventListener('load', () => {
  // seasonal
  // swiper
  let seasonalSwiper = null;

  const setSeasonalSwiper = () => {
    const seasonalSwiperElement = document.querySelector('.topSeasonal__swiper');
    if (seasonalSwiperElement) {
      if (seasonalSwiper) {
        // 前のSwiperインスタンスを削除
        seasonalSwiper.destroy(true, true);
      }

      seasonalSwiper = new Swiper(
        seasonalSwiperElement,
        swiperOptions({
          loop: false,
          slidesPerView: 'auto',
          spaceBetween: 28,
          watchOverflow: true,
          navigation: {
            nextEl: '.topSeasonal__swiperButton_next',
            prevEl: '.topSeasonal__swiperButton_prev',
          },
        })
      );
    }
  };

  // tab
  initTabs(
    '.topSeasonal__tabButton',
    'content-display',
    'content',
    'topSeasonal__tabButton--active',
    setSeasonalSwiper
  );
  initTabs(
    '.topSeasonal__tabButton',
    'seasonal-image-display',
    'image',
    'topSeasonal__tabButton--active',
    setSeasonalSwiper
  );

  // rankingセクション
  // tab
  initTabs('[data-role="top-ranking-button"]', 'top-ranking-content-display', 'template', 'dualTab__button--active');

  // newsセクション
  // tab
  initTabs('[data-role="top-news-button"]', 'top-news-content-display', 'template', 'dualTab__button--active');

  // AI協調フィルタリング
  const aiRecommend = document.querySelector('#top-ranking-ai');
  if (aiRecommend) {
    $.ajax({
      url: '/recommends?id=top-ai-recommend&data_type=top',
      beforeSend(xhr) {
        xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));
      },
      type: 'GET',
      timeout: 5000,
    });
  }

  // エリアマップセクション
  if (document.querySelector('.topArea__swiper')) {
    // アクティブなスライダーに対応する地図の更新処理を関数として定義
    const updateMapForActiveSlide = (swiperInstance) => {
      const activeSlideAreaId = swiperInstance.slides[swiperInstance.activeIndex].getAttribute('data-area-id');

      document.querySelectorAll('.topArea__areaMapImage').forEach((image) => {
        if (image.id === activeSlideAreaId) {
          image.classList.add('checked'); // 対応する地図画像に .checked クラスを付与
        } else {
          image.classList.remove('checked'); // 他の画像からは .checked クラスを削除
        }
      });
    };

    // Swiperの初期化
    const topAreaSwiper = new Swiper(
      '.topArea__swiper',
      swiperOptions({
        slidesPerView: 1,
        spaceBetween: 28,
        navigation: {
          nextEl: '.topArea__swiperButtonNext',
          prevEl: '.topArea__swiperButtonPrev',
        },
        on: {
          init: function () {
            updateMapForActiveSlide(this); // 初期化時にも対応する地図を更新
          },
          slideChange: function () {
            updateMapForActiveSlide(this); // スライドが変更された時も更新
          },
        },
      })
    );

    // <area> タグのクリックイベントリスナーを設定
    document.querySelectorAll('area').forEach((area) => {
      area.addEventListener('click', (e) => {
        e.preventDefault(); // デフォルトの動作を防止
        const areaId = area.getAttribute('data-area-id');

        // 対応する地図に.checkedクラスを付与し、他を削除
        document.querySelectorAll('.topArea__areaMapImage').forEach((image) => {
          if (image.id === areaId) {
            image.classList.add('checked');
          } else {
            image.classList.remove('checked');
          }
        });

        // 対応するスライダーのインデックスを見つける
        const targetIndex = Array.from(document.querySelectorAll('.swiper-slide[data-area-id]')).findIndex(
          (slide) => slide.getAttribute('data-area-id') === areaId
        );

        if (targetIndex !== -1) {
          topAreaSwiper.slideTo(targetIndex); // 対応するスライドに移動
        }
      });
    });
  }

  // News最新情報が表示される直前にイベント情報取非同期処理のクリックイベントを登録
  const targetElement = document.querySelector('.top__news');
  if (targetElement) {
    const observer = new IntersectionObserver(
      () => {
        const topEventsTabElement = document.querySelector('#top-news-event-template');
        if (topEventsTabElement) {
          const eventsElement = document.getElementById('top-news-event');
          eventsElement.addEventListener('click', () => {
            $.ajax({
              url: '/top_events',
              beforeSend(xhr) {
                xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));
              },
              type: 'GET',
              timeout: 5000,
            });
          });
        }
        observer.disconnect();
      },
      {
        root: null, // ビューポート
        rootMargin: '30% 0px', // 交差していると判断する領域の拡大
        threshold: [0], // 2割、4割、8割交差したときのみ検出する（デフォルトは0）
      }
    );

    // 監視を開始
    observer.observe(targetElement);
  }

  imageMapResize();
});
