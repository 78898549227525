import { setupMenuToggle, setElementHeightVariable, handleEmergencyInfo } from './module/HeaderFunctions';

const config = {
  headerSelector: '.header',
  openButtonSelector: '.header__openButton',
  activeClass: 'header--active',
  variableName: '--header-height',
  emergencySelector: '.emergency',
  emergencyDismissKey: 'emergencyInfoDismissed',
  emergencyDismissValue: 'true',
  emergencyCloseButtonSelector: '.emergency__closeButton',
  emergencyDisplayNoneClass: 'emergency--displayNone',
  breakpoint: 1024,
};

const heightVariableConfig = {
  selector: config.headerSelector,
  variableName: config.variableName,
};

const emergencyConfig = {
  emergencySelector: config.emergencySelector,
  emergencyDismissKey: config.emergencyDismissKey,
  emergencyDismissValue: config.emergencyDismissValue,
  emergencyCloseButtonSelector: config.emergencyCloseButtonSelector,
  emergencyDisplayNoneClass: config.emergencyDisplayNoneClass,
  onEmergencyChange: () => setElementHeightVariable(heightVariableConfig),
};

const setUpMenuConfig = {
  headerSelector: config.headerSelector,
  openButtonSelector: config.openButtonSelector,
  activeClass: config.activeClass,
  breakpoint: config.breakpoint,
};

const handleResizeAndLoad = () => {
  setElementHeightVariable(heightVariableConfig);
};

document.addEventListener('DOMContentLoaded', () => {
  handleEmergencyInfo(emergencyConfig);
});

window.addEventListener('load', () => {
  setupMenuToggle(setUpMenuConfig);
  handleResizeAndLoad();
});

window.addEventListener('resize', handleResizeAndLoad);
