import {
  setupFloatingButtons,
  setupSearchMenu,
} from './module/FloatButtonFunctions';

// 設定オブジェクト
const config = {
  floatButtonsSelector: '.floatButtons',
  closeButtonSelector: '.floatMenu__closeButton',
  searchButtonSelector: '[data-role="float-Button-search"]',
  searchMenuSelector: '[data-role="float-menu-search"]',
  menuActiveClass: 'floatMenu--active',
};

// DOMの読み込みが完了したら設定する
window.addEventListener('load', () => {
  // フローティングボタンの設定
  const floatingButtonsConfig = {
    floatButtonsSelector: config.floatButtonsSelector,
  };
  setupFloatingButtons(floatingButtonsConfig);


  // 検索メニューの設定
  const searchMenuConfig = {
    searchButtonSelector: config.searchButtonSelector,
    searchMenuSelector: config.searchMenuSelector,
    closeButtonSelector: config.closeButtonSelector,
    menuActiveClass: config.menuActiveClass,
  };
  setupSearchMenu(searchMenuConfig);

});
