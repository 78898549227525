const getTargetElement = (href) => {
  const [targetUrl, targetId] = href.split('#');
  return { targetUrl, targetId };
};

const getCurrentUrl = () => window.location.pathname;

const getHeaderHeight = (headerSelector) => document.querySelector(headerSelector).offsetHeight;

const getTargetPosition = (targetElement, headerHeight) => 
  targetElement.getBoundingClientRect().top + window.scrollY - headerHeight;

const scrollToPosition = (position) => {
  window.scrollTo({
    top: position,
    behavior: 'smooth',
  });
};

const handleElementClick = (event, element, headerSelector) => {
  const { targetUrl, targetId } = getTargetElement(element.getAttribute('href'));
  const currentUrl = getCurrentUrl();

  if ((targetUrl === currentUrl) || !targetUrl) {
    event.preventDefault();
    const targetElement = document.getElementById(targetId);
    const headerHeight = getHeaderHeight(headerSelector);

    if (targetElement) {
      const targetPosition = getTargetPosition(targetElement, headerHeight);
      scrollToPosition(targetPosition);

      // URLを変更する
      const newUrl = `${window.location.pathname}#${targetId}`;
      history.pushState(null, '', newUrl);
    }
  }
};

const setupEventListeners = (elements, headerSelector) => {
  elements.forEach((element) => {
    element.addEventListener('click', (event) => handleElementClick(event, element, headerSelector));
  });
};

const scrollToAnchorInUrl = (headerSelector) => {
  const url = window.location.href;

  if (url.indexOf('#') !== -1) {
    const anchor = url.split('#');
    const target = document.getElementById(anchor[anchor.length - 1]);
    const headerHeight = getHeaderHeight(headerSelector);

    if (target) {
      const position = Math.floor(target.getBoundingClientRect().top + window.scrollY) - headerHeight;
      scrollToPosition(position);
    }
  }
};

/**
 * 特定の要素へスムーススクロールする関数
 * @param {Object} config - 構成設定オブジェクト
 */
export const setupSmoothScroll = ({ scrollToSelector, headerSelector }) => {
  const scrollToElements = document.querySelectorAll(scrollToSelector);
  setupEventListeners(scrollToElements, headerSelector);
  scrollToAnchorInUrl(headerSelector);
};


/**
 * トップへ戻るボタンの表示をトグルする関数
 * @param {Object} config - 構成設定オブジェクト
 */
export const setupScrollTopButton = ({ scrollTopButtonSelector, activeClass }) => {
  const scrollTopButton = document.querySelector(scrollTopButtonSelector);
  if (scrollTopButton) {
    const toggleButtonVisibility = () => {
      if (window.scrollY > 70) {
        scrollTopButton.classList.add(activeClass);
      } else {
        scrollTopButton.classList.remove(activeClass);
      }
    };

    toggleButtonVisibility();
    window.addEventListener('scroll', toggleButtonVisibility);
    scrollTopButton.addEventListener('click', () => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    });
  }
};
