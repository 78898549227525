/**
 * 指定されたテンプレートのコンテンツを表示領域に表示する。
 * @param {string} templateId - 表示するコンテンツのテンプレート要素のID。
 * @param {string} displaySelector - コンテンツを表示する領域のセレクタ。
 */
const displayContent = (templateId, displaySelector) => {
  const template = document.getElementById(templateId);
  const contentDisplay = document.getElementById(displaySelector);
  contentDisplay.innerHTML = '';
  const clone = document.importNode(template.content, true);
  contentDisplay.appendChild(clone);
};

/**
 * 指定されたタブをアクティブにし、他のタブを非アクティブにする。
 * @param {string} activeTabId - アクティブにするタブのID。
 * @param {NodeList} tabButtons - 全タブボタンのNodeList。
 * @param {string} activeClass - アクティブなタブに適用するクラス名。
 */
const setActiveTab = (activeTabId, tabButtons, activeClass) => {
  tabButtons.forEach(button => {
    button.classList.toggle(activeClass, button.id === activeTabId);
  });
};

/**
 * 指定されたタブのコンテンツを表示し、タブをアクティブにする。任意の追加処理がある場合は実行する。
 * @param {string} activeTabId - アクティブにするタブのID。
 * @param {string} displaySelector - コンテンツを表示する領域のセレクタ。
 * @param {string} templateSuffix - テンプレートIDの接尾辞。
 * @param {string} activeClass - アクティブなタブに適用するクラス名。
 * @param {NodeList} tabButtons - 全タブボタンのNodeList。
 * @param {Function} [callback] - タブ切り替え後に実行する任意の追加処理の関数。
 */
const switchTab = (activeTabId, displaySelector, templateSuffix, activeClass, tabButtons, callback) => {
  displayContent(`${activeTabId}-${templateSuffix}`, displaySelector);
  setActiveTab(activeTabId, tabButtons, activeClass);

  // コールバック関数が提供されていれば実行
  if (typeof callback === 'function') {
    callback();
  }
};

/**
 * タブボタンにイベントリスナーを設定し、クリック時にタブの切り替えを行う。
 * @param {NodeList} tabButtons - 全タブボタンのNodeList。
 * @param {string} displaySelector - コンテンツを表示する領域のセレクタ。
 * @param {string} templateSuffix - テンプレートIDの接尾辞。
 * @param {string} activeClass - アクティブなタブに適用するクラス名。
 * @param {Function} [callback] - タブ切り替え後に実行する任意の追加処理の関数。
 */
const setupTabListeners = (tabButtons, displaySelector, templateSuffix, activeClass, callback) => {
  tabButtons.forEach(button => {
    button.addEventListener('click', () => switchTab(button.id, displaySelector, templateSuffix, activeClass, tabButtons, callback));
  });
};

/**
 * タブ機能を初期化する。最初のタブをアクティブにし、全タブボタンにイベントリスナーを設定する。
 * @param {string} tabButtonSelector - タブボタンを選択するセレクタ。
 * @param {string} displaySelector - コンテンツを表示する領域のセレクタ。
 * @param {string} templateSuffix - テンプレートIDの接尾辞。
 * @param {string} activeClass - アクティブなタブに適用するクラス名。
 * @param {Function} [callback] - タブ切り替え後に実行する任意の追加処理の関数。
 */
export const initTabs = (tabButtonSelector, displaySelector, templateSuffix, activeClass, callback) => {
  const tabButtons = document.querySelectorAll(tabButtonSelector);
  setupTabListeners(tabButtons, displaySelector, templateSuffix, activeClass, callback);

  // 初期表示のタブを設定
  if (tabButtons.length > 0) {
    switchTab(tabButtons[0].id, displaySelector, templateSuffix, activeClass, tabButtons, callback);
  }
};
