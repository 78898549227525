import { setupScrollTopButton, setupSmoothScroll } from './module/ScrollFunctions';

// ページの設定オブジェクト
const config = {
  scrollToSelector: '[data-role="scroll-to-element"]',
  headerSelector: '.header',
  scrollTopButtonSelector: '.u-scrollToTop',
  activeClass: 'active',
};

window.addEventListener('load', () => {
  // スムーススクロールの設定
  const smoothScrollConfig = {
    scrollToSelector: config.scrollToSelector,
    headerSelector: config.headerSelector,
  };
  setupSmoothScroll(smoothScrollConfig);

  // トップへ戻るボタンの設定
  const scrollTopButtonConfig = {
    scrollTopButtonSelector: config.scrollTopButtonSelector,
    activeClass: config.activeClass,
  };
  setupScrollTopButton(scrollTopButtonConfig);
});
